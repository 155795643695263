import axios from "axios";
import create from "zustand";

import { post } from "../api/client";
import { User } from "../models";
import { fetchUserPreferences } from "../services/users/users.service";
import { getCurrentTimezone } from "../utils/date.util";
import { getValue } from "../utils/object";
import { PAYMENT_PROVIDER } from "../data/enums";

type State = {
  user: User | null;
  userPreferences: any;
  isFetchingUser: boolean;
  fetchUserPreferences: () => unknown;
  fetchMe: (token?: string) => unknown;
  setUser: (user: User) => unknown;
  credit: number;
  currency: string;
  currencyCode: string;
  referralDiscount: number;
  fetchCreditBalance: () => unknown;
  setUserWelcomedAt: () => unknown;
  fetchReferralDiscount: () => unknown;
  isHomeCareProvider?: boolean;
};

export const useUserStore = create<State>((set, get) => ({
  user: null,
  userPreferences: null,
  fetchUserPreferences: async () => {
    try {
      let accessToken = localStorage.getItem("token");
      if (accessToken) {
        const response = await fetchUserPreferences(accessToken);
        if (response && response.success) {
          const { data } = response;
          set({
            userPreferences: data,
          });
        }
      }
    } catch (err) {
      console.debug(err);
    }
  },
  setUser: (userData: User) => {
    set({ user: userData });
  },
  isFetchingUser: false,
  fetchMe: (token?: string) => {
    let accessToken = token || localStorage.getItem("token");

    if (accessToken) {
      useUserStore.setState({ isFetchingUser: true });
      const localTimezone = getCurrentTimezone();
      axios
        .get("/users/me", {
          params: {
            accessToken,
            timezone: localTimezone,
          },
        })
        .then((response) => {
          if (response.data) {
            const user = response.data as User;
            const trackIdentify = get().user?.email !== user.email;

            set({
              user,
            });

            trackIdentify &&
              window.analytics.identify(user.email, {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
              });
          }
        })
        .catch((error) => {
          console.debug("error: ", error);
        })
        .finally(() => useUserStore.setState({ isFetchingUser: false }));
    }
  },

  credit: 0,
  currency: "A$",
  currencyCode: "AUD",
  referralDiscount: 20,
  fetchCreditBalance: () => {
    let accessToken = localStorage.getItem("token");

    if (accessToken) {
      axios
        .get("/users/me/credit/balance", {
          params: {
            accessToken,
          },
        })
        .then((response) => {
          if (response.data) {
            set({
              credit: response.data.balance,
              currencyCode: getValue(response, "data.currency", "AUD"),
              currency: getValue(response, "data.currencySymbol", "A$"),
            });
          }
        })
        .catch((error) => {
          console.debug("error: ", error);
        });
    }
  },
  fetchReferralDiscount: () => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      axios
        .get("api/v2/utility/referral-discount", {
          params: {
            accessToken,
            countryCode: get().user?.country || "AU",
          },
        })
        .then((response) => {
          if (response.data) {
            set({
              referralDiscount: response.data.amount,
            });
          }
        })
        .catch((error) => {
          console.debug("error: ", error);
        });
    }
  },
  setUserWelcomedAt: () => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      post("api/v2/users/welcome", {});
    }
  },
}));

export function resetUserStore() {
  useUserStore.setState({
    user: null,
    userPreferences: null,
    credit: 0,
  });
}
