import { getValue } from "../../utils/object";
import { getUrlsFromText, whiteListedUrlsForChat } from "../../utils/url";
import {
  blockedKeywordsForChat,
  TRICKY_NUMBER_PATTERN_AU,
  TRICKY_NUMBER_PATTERN_US,
} from "./personaIdentifiableConfig";

const wordToDigitMap = {
  zero: "0",
  one: "1",
  two: "2",
  three: "3",
  four: "4",
  five: "5",
  six: "6",
  seven: "7",
  eight: "8",
  nine: "9",
};
const extractPhoneNumber = (text: string): string => {
  return text.replace(/\D+/g, "");
};

const convertWordsToDigits = (text: string): string => {
  return text
    .split(/\s+/)
    .map((word) => word.toLowerCase())
    .map((word) => getValue(wordToDigitMap, word) || word)
    .join(" ");
};

const hasPhoneNumber = (text: string, textFromJoinedMessages?: boolean) => {
  const textWithoutUrl = removeUrlsFromText(text);
  let transformedText = convertWordsToDigits(textWithoutUrl).replace(/[ .\-()]/g, "");
  if (!textFromJoinedMessages) {
    transformedText = extractPhoneNumber(transformedText);
  }
  return /(\(?\+?[0-9]{1,3}\)?[- ]?)?((\(?[0-9]{3,4}\)?|[0-9]{3,4})[- ]?)?([0-9]{3,4}[- ]?[0-9]{3,4}[- ]?[0-9]{3,4}|\b[A-Z0-9]{9,}\b)/.test(
    transformedText
  );
};

const hasEmailAddress = (text: string) => {
  return /([a-z0-9_\-.+]+)@\w+(\.\w+)*/gi.test(text);
};

const hasBlockedKeywords = (text: string, wordsToCheck: string[]) => {
  const regex = new RegExp(
    wordsToCheck.map((phrase) => phrase.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")).join("|"),
    "gi"
  );
  return regex.test(text);
};

const hasCashKeyword = (text: string) => {
  return hasBlockedKeywords(text, ["cash"]);
};

const hasPrivateBookingPhrases = (text: string) => {
  return hasBlockedKeywords(text, [
    "book privately",
    "booking privately",
    "private book",
    "private booking",
    "privately book",
  ]);
};

const isWhitelisted = (url: string) => {
  return whiteListedUrlsForChat.some((pattern) => pattern.test(url));
};

const removeWords = (text: string, words: string[]) => {
  const pattern = new RegExp(words.join("|"), "gi");
  return text.replace(pattern, "");
};

const removeUrlsFromText = (text: string) => {
  const urls = getUrlsFromText(text);
  const escapedUrls = urls.map((url) => url.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
  return removeWords(text, escapedUrls);
};

const hasBannedUrls = (text: string) => {
  const urls = getUrlsFromText(text);
  return urls.some((url) => !isWhitelisted(url));
};

const hasTrickyPhoneNumberAU = (text: string) => {
  const str = text.toLowerCase().replaceAll(" ", "");

  return TRICKY_NUMBER_PATTERN_AU.some((txt) => str.startsWith(txt));
};

const hasTrickyPhoneNumberUS = (text: string) => {
  const str = text.toLowerCase().replaceAll(" ", "");

  return TRICKY_NUMBER_PATTERN_US.some((txt) => str.startsWith(txt));
};

const hasTrickyPhoneNumber = (text: string) => {
  return hasTrickyPhoneNumberAU(text) || hasTrickyPhoneNumberUS(text);
};

const hasPersonalInformation = (text: string, textFromJoinedMessages?: boolean) => {
  if (hasBannedUrls(text)) {
    return true;
  }

  const textWithoutUrl = removeUrlsFromText(text);

  return (
    hasPhoneNumber(textWithoutUrl, textFromJoinedMessages) ||
    hasEmailAddress(textWithoutUrl) ||
    hasBlockedKeywords(textWithoutUrl, blockedKeywordsForChat) ||
    hasTrickyPhoneNumber(textWithoutUrl)
  );
};

export {
  hasPersonalInformation,
  hasPhoneNumber,
  hasTrickyPhoneNumberAU,
  hasBannedUrls,
  hasCashKeyword,
  hasPrivateBookingPhrases,
};
