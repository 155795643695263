import { Box } from "@material-ui/core";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import ContentSectionControl from "../../components/ContentSectionControl";
import PaginationControl from "../../components/PaginationControl";
import AbandonBookingModal from "../../components/ProDashboard/AbandonBookingModal";
import BookingItem from "../../components/ProDashboard/BookingItem";
import NoItemsPlaceholder from "../../components/ProDashboard/NoItemsPlaceholder";
import ProDashboardHeader from "../../components/ProDashboard/ProDashboardHeader";
import Wrapper from "../../components/Wrapper";
import { BOOKINGS_SECTION_VALUES } from "../../constants/prodashboard";
import { useGetRebookings } from "../../hooks/booking/prodashboard.hooks";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import {
  AbandonedJob,
  ConfirmAbandonJobParameters,
  Job,
  PaginatedResponse2,
  TherapistBooking,
} from "../../models";
import { confirmJobAbandon } from "../../services/proDashboard/jobAbandon.service";
import { useAlertStore } from "../../stores/alert";
import { getValue } from "../../utils/object";
import BookingsView from "./Bookings/BookingsView";
import NewBookingsView from "./Bookings/NewBookingsView";
import UpdateServiceModal from "../Job/Modals/DeclineJobModal/UpdateServiceModal";
import BlockClientModal from "../../components/Client/BlockClientModal";
import { JobStatus } from "../../constants/booking";
import { Accordion } from "../../components/Accordion/Accordion";
import { Timeline } from "../../components/Timeline/Timeline";
import { BookingAccordionItem } from "../../components/ProDashboard/PastBookings/BookingAccordionItem";
import { PastBookings } from "../../components/ProDashboard/PastBookings/PastBookings";

export default function Bookings() {
  const history = useHistory();
  const { search, state } = useLocation();
  const accessToken = useAccessToken();
  const isMobile = useMobile();

  const BOOKING_SECTIONS = [
    {
      value: BOOKINGS_SECTION_VALUES.NEW,
      label: "New",
    },
    {
      value: BOOKINGS_SECTION_VALUES.UPCOMING,
      label: "Upcoming",
    },
    {
      value: BOOKINGS_SECTION_VALUES.PAST,
      label: "Past",
    },

    // Past and abandoned bookings are merged
    // {
    //   value: BOOKINGS_SECTION_VALUES.ABANDONED,
    //   label: "Abandoned",
    // },
  ];

  const perPage = 10;

  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const [currentPage, setCurrentPage] = React.useState(0);

  const [abandoning, setAbandoning] = useState(false);
  const [showPagination, setShowPagination] = useState(true);
  const [showAbandonBookingModal, setShowAbandonBookingModal] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(0);
  const [jobId, setJobId] = useState<number>();
  const [arrivalTime, setArrivalTime] = useState<string | undefined>("");
  const [selectedJob, setSelectedJob] = useState<TherapistBooking | undefined>();

  const [showUpdateServiceModal, setShowUpdateServiceModal] = useState(false);

  const _apiUrl = () => {
    const selectedSection = BOOKING_SECTIONS[selectedSectionIndex];
    if (selectedSection.value === BOOKINGS_SECTION_VALUES.NEW) {
      return `/api/v2/therapists/bookings/new?accessToken=${accessToken}&currentPage=${
        currentPage + 1
      }&perPage=${perPage}`;
    }

    if (selectedSection.value === BOOKINGS_SECTION_VALUES.UPCOMING) {
      return `/therapists/bookings/upcoming?accessToken=${accessToken}&currentPage=${
        currentPage + 1
      }&perPage=${perPage}`;
    }

    return null;
  };

  const apiUrl = _apiUrl();
  const { data, mutate, revalidate } = useSWR(apiUrl);
  // fetch direct bookings
  const { data: directBookings } = useGetRebookings({
    currentPage: currentPage + 1,
    perPage,
  });

  const _items = () => {
    const selectedSection = BOOKING_SECTIONS[selectedSectionIndex];

    let sectionItems: any = [];
    sectionItems = (getValue(data, "jobs") || []) as Job[];
    sectionItems = sectionItems.filter((item: any) => !!item);
    return sectionItems;
  };

  const _directBookingItems = () => {
    let sectionItems = (getValue(directBookings, "jobs") || []) as Job[];
    return sectionItems.filter((item: any) => !!item);
  };

  const items = _items();
  const directBookingItems = _directBookingItems();

  const _pages = () => {
    const paginatedResponse = data && (data as PaginatedResponse2);

    if (paginatedResponse) {
      const { pageCount } = paginatedResponse;
      return pageCount;
    }

    return 0;
  };

  const pages = _pages();

  useEffect(() => {
    const location = getValue(history, "location");
    const stateSection = getValue(history, "location.state.section");

    const searchParams = new URLSearchParams(location.search);
    const sectionRedirect = searchParams.get("section") || stateSection;

    const shouldShowUpdateServicesModal = getValue(location, "state.shouldShowUpdateServicesModal");

    if (shouldShowUpdateServicesModal) {
      setShowUpdateServiceModal(true);
    }

    const sectionIndex = BOOKING_SECTIONS.findIndex(({ value }) => {
      return value === (sectionRedirect || BOOKING_SECTIONS[0].value);
    });

    if (sectionIndex < 0) return;
    setSelectedSectionIndex(sectionIndex);
  }, [search]);

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedSectionIndex]);

  const noItems = !isNil(data) && isEmpty(items) && isEmpty(directBookingItems);

  const _placeholder = () => {
    const selectedSection = BOOKING_SECTIONS[selectedSectionIndex];
    switch (selectedSection.value) {
    case BOOKINGS_SECTION_VALUES.UPCOMING:
      return "No upcoming bookings";
    case BOOKINGS_SECTION_VALUES.NEW:
      return "No new bookings";
    }
    return null;
  };

  const placeholder = _placeholder();

  const onConfirmClick = async ({ id, fee, reason }: ConfirmAbandonJobParameters) => {
    if (!reason) {
      setErrorMessage("Abandon reason is required.");
    } else {
      setAbandoning(true);
      try {
        const res = await confirmJobAbandon({ id, fee, reason });
        if (res?.success) {
          await mutate();
          setSuccessMessage(res?.data?.message || "Job abandoned successfully");
          setShowAbandonBookingModal(false);
        }
      } catch (error: any) {
        setErrorMessage(error);
      } finally {
        setAbandoning(false);
      }
    }
  };

  const closeAbandonBookingModal = () => {
    setShowAbandonBookingModal(false);
  };

  const openAbandonBookingModal = (booking: TherapistBooking) => {
    setSelectedJob(booking);
    setShowAbandonBookingModal(true);
    setJobId(booking?.id);
    setArrivalTime(booking?.bookingdetail.booking?.timeOfArrival);
  };

  const openBlockClientModal = (jobId: number) => {
    setShowBlockModal(true);
    setJobId(jobId);
  };

  const handleJobOpen = ({ jobId }: { jobId: number }) => {
    history.push(`/pro/bookings/${jobId}`);
  };

  const handleConfirmedJobOpen = ({ jobId }: { jobId: number }) => {
    history.push(`/pro/confirmed-booking/${jobId}`);
  };

  const sections = BOOKING_SECTIONS.map(({ label }) => label);
  const selectedSection = BOOKING_SECTIONS[selectedSectionIndex];

  const changeTab = async (index: any) => {
    const selectedSectionValue = BOOKING_SECTIONS[index]?.value || "";
    if (index === 0) {
      history.push("/pro/bookings");
    } else {
      history.push(`/pro/bookings?section=${selectedSectionValue}`);
    }
    setSelectedSectionIndex(index);
  };

  const shouldOpenJobDetailsPage = ({ jobStatus, jobId }: { jobStatus: string; jobId: number }) => {
    if (jobStatus === JobStatus.finished || jobStatus === JobStatus.reviewLeft) {
      handleJobOpen({ jobId });
    }
  };

  return (
    <>
      <Wrapper>
        <ProDashboardHeader section="Bookings" />

        <Box mt="40px" ml={isMobile ? "16px" : "80px"} mr={isMobile ? "16px" : "80px"}>
          <ContentSectionControl
            sections={sections}
            selectedIndex={selectedSectionIndex}
            onSelectedIndex={(index) => changeTab(index)}
          />

          {noItems && (
            <Box
              width="100%"
              height="70vh"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <NoItemsPlaceholder>{placeholder}</NoItemsPlaceholder>
            </Box>
          )}

          {!noItems && [BOOKINGS_SECTION_VALUES.NEW].includes(selectedSection.value) && (
            <Box>
              {items || directBookingItems ? (
                <NewBookingsView
                  jobs={items}
                  directBookingJobs={directBookingItems}
                  handleJobOpen={handleJobOpen}
                  tab={BOOKINGS_SECTION_VALUES.NEW}
                  showPagination={setShowPagination}
                />
              ) : (
                <React.Fragment />
              )}
            </Box>
          )}

          {!noItems && [BOOKINGS_SECTION_VALUES.UPCOMING].includes(selectedSection.value) && (
            <Box>
              {items && (
                <BookingsView
                  jobs={items}
                  openAbandonBookingModal={openAbandonBookingModal}
                  tab={BOOKINGS_SECTION_VALUES.UPCOMING}
                  showPagination={setShowPagination}
                  handleJobOpen={handleJobOpen}
                />
              )}
            </Box>
          )}

          {!noItems && [BOOKINGS_SECTION_VALUES.PAST].includes(selectedSection.value) && (
            <Box>
              <PastBookings />
            </Box>
          )}

          {pages > 0 && showPagination && (
            <Box display="flex" justifyContent="flex-start" mb="55px" width="100%">
              <PaginationControl
                currentPage={currentPage}
                pages={pages}
                onPrevious={() => {
                  if (currentPage > 0) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
                onNext={() => {
                  if (currentPage < pages - 1) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              />
            </Box>
          )}
        </Box>

        {showAbandonBookingModal && (
          <AbandonBookingModal
            open={showAbandonBookingModal}
            onClose={closeAbandonBookingModal}
            abandoning={abandoning}
            onConfirm={onConfirmClick}
            jobId={jobId}
            arrivalTime={arrivalTime}
            selectedJob={selectedJob}
          />
        )}

        {jobId && showBlockModal && (
          <BlockClientModal
            jobId={jobId}
            open={showBlockModal}
            onBlockSuccess={() => {
              revalidate();
              setSuccessMessage("Client blocked.");
            }}
            onClose={() => {
              setShowBlockModal(false);
            }}
          />
        )}

        <UpdateServiceModal
          visible={showUpdateServiceModal}
          onClose={() => {
            const stateSection = getValue(state, "shouldShowUpdateServicesModal");
            // // clear the state history
            if (!!stateSection) {
              history.replace(history.location.pathname, {});
            }
            setShowUpdateServiceModal(false);
          }}
        />
      </Wrapper>
    </>
  );
}
