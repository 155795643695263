import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import Button, { ButtonType } from "../../../../components/Button";
import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import DayPicker from "react-day-picker";
import Dropdown, { DropdownOption } from "../../../../components/Dropdown";
import {
  convertDatetoTimezoneMoment,
  convertToMoment,
  getCurrentTimeInMinutes,
  isDateObjectToday,
  resetTimeFromDate,
} from "../../../../utils/date.util";
import { useServicesStore } from "../../../../stores/booking";
import { getTimeOptions, minutesToTime } from "../../../../helpers/time";
import moment, { Moment } from "moment";
import { useAlertStore } from "../../../../stores/alert";
import "../../../../styles/DayPicker.css";
import { Colors } from "../../../../constants/colors";
import TextField from "../../../../components/TextField";
import { useGetStandardRate } from "../../../../hooks/job/alternateDateAndTime.hooks";
import { limitDecimals } from "../../../../utils/number";
import { OfferRate } from "../../../../components/ProDashboard/OfferRate/OfferRate";
import { FontFamily, FontSize, FontWeight, LineHeight, Spacing } from "../../../../components/v2/Styled/enum";
import { isNumber } from "../../../../helpers/validation";

interface Props {
  visible: boolean;
  isMobile: boolean;
  isTab: boolean;
  onClose: () => unknown;
  timezone: string;
  offeredTime: string;
  jobId: string;
  updateOfferId: number | null;
  title?: string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  onConfirm: ({
    jobId,
    selectedDateTime,
    preferredPayout,
  }: {
    jobId: string;
    selectedDateTime: Moment;
    preferredPayout: string;
  }) => unknown;
  actionName?: string;
  currency?: string;
  offeredPrice?: string;
}
const OfferAlternativeModal = ({
  visible,
  onClose,
  isMobile,
  isTab,
  jobId,
  offeredTime,
  timezone,
  title,
  label = "Booking start time",
  minDate,
  maxDate,
  onConfirm,
  actionName = "Confirm",
  currency,
  offeredPrice,
}: Props) => {
  const { timeRange } = useServicesStore();
  const { setErrorMessage } = useAlertStore();
  const timeOptions = getTimeOptions(timeRange) as DropdownOption[];
  const { data: standardRateData, mutate: getStandardRate } = useGetStandardRate();

  const standardRate = standardRateData?.data?.serviceFee;
  const maximumOfferRate = standardRateData?.data?.maximumOfferRate;

  const earliestTime = offeredTime;

  const bookingTimezone = timezone;

  const [selectedDay, setSelectedDay] = useState<Date>();
  const [modifiers, setModifers] = useState({ from: selectedDay, to: selectedDay });

  const [earliestTimeInMinutes, setEarliestTime] = useState<number>();
  const [offeredRate, setOfferedRate] = useState<string>("");
  const [isTouched, setIsTouched] = useState(false);

  const initializeDateAndPrice = () => {
    if (earliestTime) {
      handleDayChange(new Date(earliestTime));
    }
    if (offeredPrice) {
      setOfferedRate(offeredPrice);
      setIsTouched(true);
    }
    else {
      setOfferedRate("");
    }
  };

  useEffect(() => {
    if(visible){
      initializeDateAndPrice();
    }
  }, [visible]);

  useEffect(() => {
    initialize();
  }, [earliestTime]);

  const initialize = () => {
    if (earliestTime) {
      const momentEarliestTime = convertToMoment(earliestTime, bookingTimezone);
      const earliestTimeInMinutes = moment.duration(momentEarliestTime.format("HH:mm")).asMinutes();
      setEarliestTime(earliestTimeInMinutes);
    }
  };

  const filteredTimeRange = (selectedDay: any) => {
    if (!selectedDay) return [];

    const isToday = isDateObjectToday(selectedDay, bookingTimezone);
    if (!isToday) return timeOptions;

    const minFeasibleEarliest = getCurrentTimeInMinutes(bookingTimezone);
    return timeOptions.filter(({ value }) => value >= minFeasibleEarliest);
  };

  const convertTimeInMinutesToProperTime = (day: any, time: any) => {
    const earliestTime = minutesToTime(time || 0);

    const earlyTime = convertDatetoTimezoneMoment(day, bookingTimezone)
      .set("hour", earliestTime.hour)
      .set("minute", earliestTime.mins)
      .set("second", 0)
      .set("millisecond", 0);

    return earlyTime;
  };

  const handleDayChange = (selectedDay: Date) => {
    const day = resetTimeFromDate(selectedDay);
    setModifers({ from: day, to: day });
    setSelectedDay(day);
  };

  const handleTimeChange = (selectedTime: any) => {
    setEarliestTime(selectedTime);
  };

  const validateOfferRate = () => {
    if (offeredRate && offeredRate < standardRate) {
      setErrorMessage(
        "Please enter the rate not less than the standard price set by the platform for this booking."
      );
      return;
    }

    if (+offeredRate > +maximumOfferRate) {
      setErrorMessage(`The maximum amount you can enter for this booking is ${currency}${maximumOfferRate}`);
      return;
    }

    return true;
  };

  const handleConfirm = () => {
    if (!selectedDay) {
      setErrorMessage("Please select date.");
      return;
    }
    if (!validateOfferRate()) return;

    const actualOffered = convertTimeInMinutesToProperTime(selectedDay, earliestTimeInMinutes);
    const body = {
      jobId,
      selectedDateTime: actualOffered,
      preferredPayout: !!offeredRate ? offeredRate : standardRate,
    };
    onConfirm(body);
  };

  if (!maxDate) {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    maxDate = oneYearFromNow;
  }

  const refetchStandardRate = () => {
    if (!selectedDay || !earliestTimeInMinutes || !jobId) return;
    const timeOfArrival = convertTimeInMinutesToProperTime(selectedDay, earliestTimeInMinutes);
    getStandardRate({ body: { timeOfArrival }, jobId: jobId });
  };

  useEffect(() => {
    refetchStandardRate();
  }, [selectedDay, earliestTimeInMinutes]);

  const handlePriceChange = (value: string) => {
    if(!isNumber(value)) return;

    setIsTouched(true);
    if (value !== "") {
      value = limitDecimals(value) as string;
    }
    setOfferedRate(value);
  };

  return (
    <ContentModal
      visible={visible}
      divider={false}
      onClose={onClose}
      maxWidth={isMobile || isTab ? "xs" : "md"}
      fullScreen={isMobile}
      actionStyle={{ padding: !isMobile?"32px 74px 16px 74px":"22px 46px 8px 46px", gap: 16 }}
      actions={[
        <Button title="Close" type={ButtonType.outlined} onClick={onClose} />,
        <Button title={actionName} type={ButtonType.secondary} onClick={handleConfirm} />,
      ]}
    >

      <Box style = {{ padding: "36px 26px 36px 26px" }}>
        <Box 
          style ={{
            marginLeft: !isMobile?Spacing.S5:undefined,
            marginRight: !isMobile?Spacing.S5:undefined
          }}
        >
          <Box style = {{
            fontFamily: FontFamily.Museo,
            fontWeight:FontWeight.Bold,
            color: Colors.NightBlue,
            fontSize: FontSize.F30,
           
          }}>
          Make an offer
          </Box>
          <Box style = {{
            fontFamily: FontFamily.Museo,
            fontWeight:FontWeight.Medium,
            color: Colors.Dusk,
            fontSize: FontSize.F18,
            lineHeight: LineHeight.L27,
            marginTop: Spacing.S4
          }}>
          Offer the client an alternative availability and/or an amount that would work for you. We will send your offer to the client (along with other offers from other providers). If the client accepts your offer, the booking will be confirmed and you'll be notified.
          </Box>
        </Box>

        <Box style={{
          fontSize: FontSize.F24,
          fontFamily: FontFamily.Museo,
          color: Colors.NightBlue,
          fontWeight: FontWeight.Bold,
          marginLeft: !isMobile?Spacing.S5:undefined,
          marginRight: !isMobile?Spacing.S5:undefined,
          marginTop: Spacing.S10
        }}> Offer availability</Box>
        <Box
          display="flex"
          alignItems={isMobile || isTab ? "center" : undefined}
          flexDirection={isMobile || isTab ? "column" : "row"}
          marginTop="14px"
        >
          <Box
            width={isMobile || isTab ? "100%" : "50%"}
            display={isMobile || isTab ? "flex" : undefined}
            alignItems={isMobile || isTab ? "center" : undefined}
            flexDirection={isMobile || isTab ? "column" : undefined}
          >
            <DayPicker
              firstDayOfWeek={1}
              onDayClick={(day, modifiers) => {
                if (modifiers.disable) return;
                handleDayChange(day);
              }}
              disabledDays={{
                before: minDate || new Date(),
                after: maxDate,
              }}
              selectedDays={[modifiers?.to, { from: modifiers?.from, to: modifiers?.to }]}
              modifiers={modifiers}
            />
          </Box>
          <Box
            width={isMobile || isTab ? "95%" : "50%"}
            pr={!isMobile && !isTab ? "32px" : undefined}
          >
            <Dropdown
              title={label}
              options={filteredTimeRange(selectedDay)}
              onSelectedOption={(option) => {
                handleTimeChange(option.value);
              }}
              selectedOption={timeOptions.find((option) => option.value === earliestTimeInMinutes)}
            />
          </Box>
      
        </Box>

        <Box style={{
          marginLeft: !isMobile?Spacing.S5:undefined,
          marginRight: !isMobile?Spacing.S5:undefined,
          marginTop: Spacing.S4,
        }}>
          <OfferRate 
            standardRate = {standardRate ?? ""}
            rateToOffer = {offeredRate}
            onRateToOfferChange = { (value) => {handlePriceChange(value);}} 
            currency = {currency}
            maximumOfferRate = {maximumOfferRate}
          />
        </Box>
      </Box>
   
    </ContentModal>
  );
};
export default OfferAlternativeModal;
