import React from "react";
import { useMutation } from "@tanstack/react-query";

import { post } from "../../api/client";

export const useBlockClient = () =>
  useMutation(
    (data: { jobId: number, blockReason: string }) => {
      return post(`therapists/${data.jobId}/blockClient`, { blockReason: data.blockReason });
    }
  );

export const useUnblockClient = () =>
  useMutation(
    ({ jobId }: any) => {
      return post(`therapists/${jobId}/unblockClient`, { jobId });
    }
  );

  export const useUnblockClientWithId = () =>
    useMutation(
      ({ clientId }: any) => {
        return post(`therapists/unblockClient/${clientId}`, { clientId });
      }
    );
